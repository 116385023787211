import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileMenuComponent} from './mobile-menu/mobile-menu.component';
import {HeaderViewComponent} from './header-view/header-view.component';
import {MenuComponent} from './menu/menu.component';
import {MatTableModule as MatTableModule} from '@angular/material/table';
import {MatListModule as MatListModule} from '@angular/material/list';
import {MatButtonModule as MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FooterViewComponent} from './footer/footer-view/footer-view.component';
import {ExternalLinkComponent} from './external-link/external-link.component';
import {LoaderComponent} from './loader/loader.component';
import {FixedMenuComponent} from './fixed-menu/fixed-menu.component';
import {FixedHeaderComponent} from './fixed-header/fixed-header.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PortalMenuComponent} from './portal-layout/menu/menu.component';
import {PortalHeaderViewComponent} from './portal-layout/header-view/header-view.component';
import {PortalMobileMenuComponent} from './portal-layout/mobile-menu/mobile-menu.component';
import {PortalFooterViewComponent} from './portal-layout/footer/footer-view/footer-view.component';

@NgModule({
  declarations: [
    MobileMenuComponent,
    HeaderViewComponent,
    MobileMenuComponent,
    MenuComponent,
    FooterViewComponent,
    ExternalLinkComponent,
    LoaderComponent,
    FixedMenuComponent,
    FixedHeaderComponent,
    PortalMenuComponent,
    PortalHeaderViewComponent,
    PortalMobileMenuComponent,
    PortalFooterViewComponent
  ],
  exports: [
    MobileMenuComponent,
    HeaderViewComponent,
    MenuComponent,
    FooterViewComponent,
    ExternalLinkComponent,
    LoaderComponent,
    FixedHeaderComponent,
    FixedMenuComponent,
    FooterViewComponent,
    PortalMenuComponent,
    PortalHeaderViewComponent,
    PortalMobileMenuComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatListModule,
    MatButtonModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    MatExpansionModule,
    // FooterModule,
    NgbModule
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SharedModule {}
