<header>
  <!-- Purple Banner -->
  <div id="network-branding" class="container-fluid text-white">
    <div class="container d-flex align-items-center justify-content-center">
      <img class="masthead-logo" src="./assets/images/masthead-hhs-logo.png" alt="HHS" />
      <a class="masthead-link hhs-link" href="https://www.hhs.gov/">
        <span class="d-none d-xl-inline-block">U.S. Department of Health and Human Services</span>
        <span class="d-xl-none d-inline-block">HHS</span>
      </a>
      <img src="./assets/images/masthead-divider.png" alt="|" class="masthead-divider" />
      <img class="masthead-logo" src="./assets/images/masthead-nih-logo.png" alt="NIH" />
      <a class="masthead-link nih-link" href="https://www.nih.gov/">
        <span class="d-none d-lg-inline-block">National Institutes of Health</span>
        <span class="d-lg-none d-inline-block">NIH</span>
      </a>
      <img src="./assets/images/masthead-divider.png" alt="|" class="masthead-divider" />
      <img class="masthead-logo" src="./assets/images/masthead-nih-logo.png" alt="NCATS" />
      <a class="masthead-link ncats-link" href="https://www.ncats.nih.gov/">
        <span class="d-none d-md-inline-block">National Center for Advancing Translational Sciences</span>
        <span class="d-md-none d-inline-block">NCATS</span>
      </a>
    </div>
  </div>
</header>
