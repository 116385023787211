<div class="container-fluid p-0 min-fixed">
  <div
    class="container"
    style="
      background-color: #fff;
      border-radius: 1em;
      border: 2px solid #77317d;
      margin: 2em;
      padding-left: 2em;
      padding-right: 2em;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 20px;
    "
  >
    <p style="padding-top: 13px; text-align: center; color: #77317d">
      This repository is under review for potential modification in compliance with Administration directives.
    </p>
  </div>
  <nav class="navbar navbar-expand pl-4 py-0" id="primary-nav">
    <div class="container max-fixed">
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav nav-fill w-100" id="navbarNavDropdown">
          <li
            [ngClass]="item?.customClass"
            *ngFor="let item of menuItems?.menu"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}"
          >
            <!--Single Item Menu without Dropdown-->
            <span *ngIf="item.dropdown === null">
              <span>
                <a class="nav-link" href="{{ item.href }}">{{ item.name }}</a>
              </span>
            </span>

            <!--Menu Item with Dropdown-->
            <span
              *ngIf="item.dropdown !== null"
              class="nav-item dropdown"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}"
            >
              <div ngbDropdown class="d-inline-block">
                <!--DropDown Menu Name-->
                <a
                  class="nav-link dropdown-toggle"
                  id="aboutNavDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ngbDropdownToggle
                >
                  {{ item.name }}
                </a>

                <!--DropDown Item Container-->
                <li ngbDropdownMenu class="dropdown-menu px-2 dropdown-submenu" aria-labelledby="SARSNavDropdown">
                  <span *ngFor="let sub of item.dropdown">
                    <!--Sub Section-->
                    <span *ngIf="!sub.dropdown">
                      <h3>{{ sub.subHeadingText }}</h3>
                      <span *ngFor="let subItem of sub.subheadingItems; let i = index">
                        <a class="dropdown-item" routerLink="{{ subItem.href }}">
                          <span *ngIf="subItem.newMark === false" class="subItemName">{{ subItem.name }}</span>
                          <span *ngIf="subItem.newMark === true" class="subItemName" style="font-style: italic">
                            {{ subItem.name }}</span
                          >
                          <span *ngIf="subItem.newMark === true" class="newMark">NEW!</span>
                          <span *ngIf="subItem.archived === true" class="archivedMark">Archived</span>
                          <p class="m-0">{{ subItem.subText }}</p>
                        </a>
                      </span>
                    </span>
                  </span>
                </li>
              </div>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
