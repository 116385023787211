import {Component, Inject, OnInit} from '@angular/core';
import {EventService, EVENT_SERVICE} from '@odp/shared';

@Component({
  selector: 'app-portal-header-view',
  templateUrl: './header-view.component.html',
  styleUrls: ['./header-view.component.scss']
})
export class PortalHeaderViewComponent {
  constructor(private eventService: EventService) {}

  menuClick() {
    this.eventService.get('menuClick').next(true);
  }
}
