import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {EventService, EVENT_SERVICE} from '@odp/shared';

@Component({
  selector: 'app-portal-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class PortalMenuComponent {
  isNavbarCollapsed = true;
  menuItems!: any;
  secondSub!: any;
  constructor(@Inject(EVENT_SERVICE) private eventService: EventService, private httpClient: HttpClient) {
    this.httpClient.get('assets/menu.json').subscribe((data) => {
      this.menuItems = data;
    });
  }

  menuClick() {
    this.eventService.get('menuClick').next(true);
  }
}
