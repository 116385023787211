<!-- Desktop Header -->
<div class="brand-container">
  <div
    class="container"
    style="
      background-color: #fff;
      border-radius: 1em;
      border: 2px solid #77317d;
      margin: 2em;
      padding-left: 2em;
      padding-right: 2em;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 20px;
    "
  >
    <p style="padding-top: 13px; text-align: center; color: #77317d">
      This repository is under review for potential modification in compliance with Administration directives.
    </p>
  </div>
  <div class="main-header row no-gutters">
    <div class="col-3 col-md-2 pos-abs">
      <a href="https://ncats.nih.gov/">
        <img src="./assets/svg/ncats.svg" alt="NCATS" class="main-logo img-fluid" />
      </a>
    </div>
    <div class="col-12 col-md-12 menu-holder">
      <nav class="navbar navbar-expand-lg p-0" id="primary-nav">
        <div class="container">
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav nav-fill w-100" id="navbarNavDropdown">
              <li
                [ngClass]="{'about-menu': item.name === 'About', itemCustomClass: item?.customClass}"
                *ngFor="let item of menuItems?.menu"
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{exact: true}"
              >
                <!--Single Item Menu without Dropdown-->
                <span *ngIf="item.dropdown === null">
                  <span>
                    <a class="nav-link" routerLink="{{ item.href }}">{{ item.name }}</a>
                  </span>
                </span>

                <!--Menu Item with Dropdown-->
                <span
                  *ngIf="item.dropdown !== null"
                  class="nav-item dropdown"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{exact: true}"
                >
                  <div ngbDropdown class="d-inline-block">
                    <!--DropDown Menu Name-->
                    <a
                      class="nav-link dropdown-toggle"
                      id="aboutNavDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      ngbDropdownToggle
                    >
                      {{ item.name }}
                    </a>

                    <!--DropDown Item Container-->
                    <li ngbDropdownMenu class="dropdown-menu px-2 dropdown-submenu" aria-labelledby="SARSNavDropdown">
                      <span *ngFor="let sub of item.dropdown">
                        <!--Sub Section-->
                        <span *ngIf="!sub.dropdown">
                          <h3>{{ sub.subHeadingText }}</h3>
                          <span *ngFor="let subItem of sub.subheadingItems; let i = index">
                            <a class="dropdown-item" routerLink="{{ subItem.href }}">
                              <span *ngIf="subItem.newMark === false" class="subItemName">{{ subItem.name }}</span>
                              <span *ngIf="subItem.newMark === true" class="subItemName" style="font-style: italic">
                                {{ subItem.name }}</span
                              >
                              <span *ngIf="subItem.newMark === true" class="newMark">NEW!</span>
                              <span *ngIf="subItem.archived === true" class="archivedMark">Archived</span>
                              <p class="m-0 sub-text">{{ subItem.subText }}</p>
                            </a>
                          </span>
                        </span>
                      </span>
                    </li>
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <!-- Mobile Header -->
  <div id="mobile-nav" class="container d-block d-lg-none py-2">
    <div class="row no-gutters align-items-center justify-content-center">
      <div class="col-6 text-center img-fluid">
        <a class="text-dec-none" href="https://ncats.nih.gov/">
          <img width="240" src="/assets/svg/ncats.svg" alt="home" />
          <!-- <div class="ml-2 webName">
            <a [routerLink]="['/covid19']">OpenData Portal</a>
          </div> -->
        </a>
      </div>
      <div class="col-4 text-right">
        <button (click)="menuClick()" class="navbar-toggler" type="button" id="menu-toggle" data-toggle="collapse">
          <i class="fas fa-bars fa-lg"></i>
        </button>
      </div>
    </div>
  </div>
</div>
